<template>
  <div id="buy">
    <router-view v-bind.sync="form"></router-view>
  </div>
</template>

<script>
export default {
  name: 'ItemCreateParent',
  data () {
    return {
      form: {
        _name: '',  // router-viewにnameは予約後で渡せない
        price: 0,
        value: 1,
        detail: '',
        accept: false,
        photos: []
      }
    }
  },
}
</script>
